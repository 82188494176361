<div class="component" [ngClass]="{'submenu-panel': isSubmenu, 'is-max-limit': !isSubmenu, 'pointer-events-none': isPointerDragging}" (mouseleave)="hideSubMenu()" #root>
  <div *ngIf="0 < title.length" class="title">{{title}}</div>
  <ul>
    <ng-container *ngFor="let action of actions">
      <ng-container *ngTemplateOutlet="action?.type === 'separator' ? separator : actionMenu; context: { action: action }"></ng-container>
    </ng-container>
  </ul>
</div>
<ng-template #actionMenu let-action="action">
  <li class="hasHighlight" (click)="doAction(action)" (mouseenter)="showSubMenu(action)">
    <div class="menu-title">{{action.name}}</div>
    <div *ngIf="action.subActions" class="sub-menu-arrow">
      <i class="material-icons" style="font-size: 1em; vertical-align: bottom;">arrow_forward_ios</i>
    </div>
    <context-menu *ngIf="subMenu && parentMenu === action" [title]="action.name" [actions]="subMenu" [isSubmenu]="true"></context-menu>
  </li>
</ng-template>
<ng-template #separator let-action="action">
  <li>
    <hr class="separator" />
  </li>
</ng-template>