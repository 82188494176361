<button (click)="openModal()">画像を選ぶ</button>
<div>
  <img height="120" [src]="tableBackgroundImage.url | safe: 'resourceUrl'">
</div>
<input [(ngModel)]="name" placeholder="Name" name="name" />
<div>
  <div>
    <input [(ngModel)]="size" type="range" min="{{minSize}}" max="{{maxSize}}" name="size" /> Size : <input [(ngModel)]="size"
      type="number" min="{{minSize}}" max="{{maxSize}}">
  </div>
  <button (click)="createGameCharacter()">キャラクターを作る</button>
  <button (click)="createGameTableMask()">マップマスクを作る</button>
  <!--
  <div>
    <button (click)="createGameCharacterForXML(xml)">↓のXMLからキャラクターを作る</button>
    <textarea style="width:100%; height:5em; resize: none;" [(ngModel)]="xml" placeholder="XML"></textarea>
  </div>
  -->
</div>