<div class="flex-container">
  <div style="flex-grow: 0;">
    <form>
      <div class="chat-tab">
        <label *ngFor="let chatTab of chatMessageService.chatTabs">
          <input name="chat-tab" type="radio" value="{{chatTab.identifier}}" ng-control="options" [(ngModel)]="chatTabidentifier">
          <div>{{chatTab.name}}<badge *ngIf="chatTab.hasUnread" class="badge" [count]="chatTab.unreadLength"></badge></div>
        </label>
      </div>
    </form>
    <chat-input [onlyCharacters]="true" [chatTabidentifier]="chatTabidentifier" 
    [(gameType)]="gameType" [(sendFrom)]="sendFrom" [(text)]="text" (chat)="sendChat($event)" #chatInput></chat-input>
    <div *ngIf="isEdit" class="edit-info"><i class="material-icons" style="vertical-align: bottom; size:0.8rem;">info_outline</i> チャットパレット編集中です</div>
  </div>
  <div *ngIf="!isEdit" style="flex-grow: 1; height: 0; min-height: 100px;">
    <select class="palette" style="overflow-y: auto;" size="5" (input)="selectPalette($event.target.value)" (click)="clickPalette($event.target.value)" #chatPlette>
      <ng-container *ngIf="palette">
        <option *ngFor="let palette of palette.getPalette()" value="{{palette}}">{{palette}}</option>
      </ng-container>
    </select>
  </div>
  <div *ngIf="isEdit" style="flex-grow: 1; height: 0; min-height: 100px;">
    <textarea class="palette" style="resize: none;" [(ngModel)]="editPalette" placeholder="チャットパレット"></textarea>
  </div>
  <div style="flex-grow: 0;">
    <button type="submit" (click)="toggleEditMode()">
      <span *ngIf="!isEdit">チャットパレットの編集</span>
      <span *ngIf="isEdit">チャットパレットを確定</span>
    </button>
  </div>
</div>
