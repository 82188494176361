<div *ngIf="!isConnected;else other_content">
  <div>ルーム名 : <input class="input" (input)="calcPeerId($event.target.value, password)" [(ngModel)]="roomName" required minlength="1" maxlength="128" #roomNameValidation="ngModel"
      placeholder="ルームの名前は必須" /></div>
  <div>パスワード: <input class="input" (input)="calcPeerId(roomName, $event.target.value)" [(ngModel)]="password" maxlength="12" #passwordValidation="ngModel" placeholder="空ならパスワードなし"
    /></div>
  <hr/>
  <div *ngIf="roomNameValidation.errors && (roomNameValidation.dirty || roomNameValidation.touched)">
    <div [hidden]="!roomNameValidation.errors.required">
      ルームの名前は必須です。
    </div>
    <div [hidden]="!roomNameValidation.errors.minlength">
      ルームの名前は必須です。
    </div>
    <div [hidden]="!roomNameValidation.errors.maxlength">
      ルーム名前の長さは128以内にしてください。
    </div>
  </div>
  <div *ngIf="passwordValidation.errors && (passwordValidation.dirty || passwordValidation.touched)">
    <div [hidden]="!passwordValidation.errors.maxlength">
      パスワードの長さは12以内にしてください。
    </div>
  </div>
  <div *ngIf="!validateLength">
    ルームの名前とパスワードの合計が長すぎるようです。どちらかを少し短くしてみてください。
  </div>
  <div>
    <button [disabled]="!roomNameValidation.valid || !passwordValidation.valid || !validateLength" (click)="createRoom()">新しいルームを作成</button>
  </div>
</div>
<div><span style="font-size: 12px;">※作成したルームは参加者が0人になった時点で解散します。ルームの状態を次回に持ち越したい場合は必ず「保存」してください。</span></div>
<ng-template #other_content>
  <span style="font-size: 12px;">他の参加者と接続したままではルーム作成を行えません。<br>別のルームに接続したい場合は、他の参加者との接続を切ってください。<br>（※ページリロードで切断できます。）</span>
</ng-template>
