<ng-container *ngIf="gameDataElement">
  <ng-container *ngIf="gameDataElement.children.length < 1">
    <div *ngIf="isEdit" class="table-cell table-cell_solid">
      <ng-container *ngTemplateOutlet="editButtons"></ng-container>
      <select (change)="setElementType($event.target.value)" [ngModel]="gameDataElement.type">
        <option value="">通常</option>
        <option value="numberResource">リソース</option>
        <option value="note">ノート</option>
      </select>
    </div>
    <div class="table-cell table-cell_solid">
      <ng-container *ngIf="isTagLocked">
        <div style="font-family: Cambria, Georgia; font-size: 0.9rem;">{{name}}</div>
      </ng-container>
      <ng-container *ngIf="!isTagLocked">
        <div class="hidden-spacer" style="font-family: Cambria, Georgia; font-size: 0.9rem;">{{name}}</div>
        <input style="width:100%; min-width:10px; font-family: Cambria, Georgia; font-size: 0.9rem;" size="1"
          [(ngModel)]="name" placeholder="Value" />
      </ng-container>
    </div>
    <div class="table-cell">
      <ng-container [ngSwitch]="gameDataElement.type">
        <ng-container *ngSwitchCase="'numberResource'">
          <input [style]="'font-size: 1.0em; min-width: 90px; width: 90%; --min: 0; --max: ' + value + '; --val:' + currentValue +';' | safe: 'style'"
            [(ngModel)]="currentValue" type="range" min="0" max="{{value}}" />
          <br>
          <input style="width: 5em; text-align: right;" size="1" type="number" [(ngModel)]="currentValue" placeholder="Value" />
          /
          <input style="width: 5em;" size="1" type="number" [(ngModel)]="value" placeholder="Value" />
        </ng-container>
        <ng-container *ngSwitchCase="'note'">
          <textarea style="width:100%; height:5em; min-width:50px; resize: none; font-size: 0.8em;" [(ngModel)]="value" placeholder="Note"></textarea>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <!-- size属性がないと小さくならない -->
          <div class="hidden-spacer" style="font-size: 1.0em; font-family: Cambria, Georgia;">{{value}}</div>
          <input style="width:100%; min-width:10px;" size="1" [(ngModel)]="value" placeholder="Value" />
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="0 < gameDataElement.children.length">
    <div *ngIf="isEdit" class="table-cell table-cell_solid" style="vertical-align: top;">
      <ng-container *ngTemplateOutlet="editButtons"></ng-container>
    </div>
    <div class="table-cell table-cell_inner-table" style="padding:0; width:0;"></div>
    <div class="table-cell_inner-table">
      <div class="table-cell_inner-table-title">
        <ng-container *ngIf="isTagLocked">
          <div style="padding: 2px; font-family: Cambria, Georgia; font-size: 1.0rem; font-weight: bold;">{{name}}</div>
        </ng-container>
        <ng-container *ngIf="!isTagLocked">
          <input style="width:100%; min-width:10px; font-family: Cambria, Georgia; font-size: 1.0rem; font-weight: bold;"
            size="1" [(ngModel)]="name" placeholder="Value" />
        </ng-container>
      </div>
      <div class="table" *ngIf="0 < gameDataElement.children.length">
        <div game-data-element class="table-row" *ngFor="let childElement of gameDataElement.children"
          [gameDataElement]="childElement" [isEdit]="isEdit" [isTagLocked]="isTagLocked"></div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #editButtons>
  <button (click)="addElement()"><i class="material-icons">add_circle</i></button>
  <button (click)="upElement()"><i class="material-icons">arrow_upward</i></button>
  <br>
  <button (click)="deleteElement()"><i class="material-icons">remove_circle_outline</i></button>
  <button (click)="downElement()"><i class="material-icons">arrow_downward</i></button>
</ng-template>