<div class="component is-pointer-events-none" [@fadeInOut]="'in'" appDraggable #draggablePanel>
  <ng-container [ngSwitch]="tabletopObject.aliasName">
    <ng-container *ngSwitchCase="'numberResource'">
    </ng-container>
    <ng-container *ngSwitchCase="'card'">
      <ng-container *ngTemplateOutlet="overviewCard; context: { card: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'card-stack'">
      <ng-container *ngTemplateOutlet="overviewCard; context: { card: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'text-note'">
      <ng-container *ngTemplateOutlet="overviewNote; context: { note: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'character'">
      <ng-container *ngTemplateOutlet="overviewCharacter; context: { character: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'dice-symbol'">
      <ng-container *ngTemplateOutlet="overviewDiceSymbol; context: { dice: tabletopObject }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="overviewCharacter; context: { character: tabletopObject }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="isOpenImageView">
  <ng-container *ngTemplateOutlet="imageView"></ng-container>
</ng-container>

<ng-template #overviewCard let-card="card">
  <div class="component-content is-small-size"
    [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
    <div *ngIf="hasImage" style="position: relative;">
      <img class="card-image" [src]="imageUrl | safe: 'resourceUrl'" />
      <button class="zoom-button" (click)="chanageImageView(true)">
        <i class="material-icons">zoom_in</i>
      </button>
      <div *ngIf="card.hasOwner && 0 < card.ownerName.length" class="component-owner position-right-bottom"
        [ngClass]="{'with-button-marign': hasImage}">
        <div class="owner-tag">{{card.ownerName}}</div>
      </div>
    </div>
    <div *ngIf="0 < card?.name?.length" class="component-title title-background"
      [ngClass]="{'with-button-marign': hasImage}">
      <ng-container *ngIf="card.isVisible == null || card.isVisible">{{card.name}}</ng-container>
      <ng-container *ngIf="card.isVisible != null && !card.isVisible">カード</ng-container>
    </div>
    <div class="component-background">
      <div class="grid-container-root" *ngIf="hasDataElms && card.isVisible">
        <div class="grid-container">
          <ng-container *ngFor="let dataElm of dataElms">
            <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: dataElm }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #overviewNote let-note="note">
  <div class="component-content is-large-size"
    [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
    <div class="component-title title-background">{{note.title}}</div>
    <div class="component-background text-note-container">
      <div style="position: relative;">
        <div class="is-fill">
          <textarea class="textarea" [(ngModel)]="note.text" spellcheck="false"></textarea>
        </div>
        <div class="textarea-spacer">{{note.text}}</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #overviewCharacter let-character="character">
  <div class="component-content character-grid-container is-middle-size">
    <div class="title-bg-area title-background"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
    </div>
    <div class="image-area character-image-box"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }"
      *ngIf="hasImage">
      <img class="character-image" [src]="imageUrl | safe: 'resourceUrl'" />
    </div>
    <div class="title-area component-title" [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging,
    'pointer-events-none': this.isPointerDragging }">{{character.name}}</div>
    <div class="void-area is-pointer-events-none"></div>
    <div class="dataElm-area component-background"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      <div class="grid-container-root" *ngIf="hasDataElms">
        <!--<ng-container *ngFor="let dataElm of dataElms">-->
        <ng-container *ngFor="let dataElm of inventoryDataElms">
          <ng-container *ngIf="dataElm && dataElm.name !== newLineString">
            <div class="grid-container">
              <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: dataElm }"></ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #overviewDiceSymbol let-dice="dice">
  <div class="component-content character-grid-container is-middle-size">
    <div class="title-bg-area title-background"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
    </div>
    <div class="title-area component-title"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      {{dice.name}}:
      <ng-container *ngIf="dice.isVisible">{{dice.face}}</ng-container>
      <ng-container *ngIf="!dice.isVisible">???</ng-container>
    </div>
    <div *ngIf="dice.hasOwner && 0 < dice.ownerName.length" class="owner-name-area component-owner"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      <div class="owner-tag">{{dice.ownerName}}
      </div>
    </div>
    <div class="image-area character-image-box"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }"
      *ngIf="hasImage">
      <img class="character-image" [ngClass]="{'is-black-mask': !dice.isVisible, 'is-harf-black-mask': dice.isMine}"
        [src]="imageUrl | safe: 'resourceUrl'" />
    </div>
    <div class="void-area is-pointer-events-none"></div>
    <div class="dataElm-area component-background"
      [ngClass]="{ 'is-pointer-events-auto': !this.isPointerDragging, 'pointer-events-none': this.isPointerDragging }">
      <div class="grid-container-root" *ngIf="hasDataElms && dice.isVisible">
        <div class="grid-container">
          <ng-container *ngFor="let dataElm of dataElms">
            <ng-container *ngIf="dataElm && dataElm.name !== newLineString">
              <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: dataElm }"></ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dataElement let-dataElm="dataElm">
  <ng-container *ngIf="0 < dataElm.children.length; then thenBlock; else elseBlock"></ng-container>
  <ng-template #thenBlock>
    <div class="section" style="padding-top: 0.5em;">
      <div class="section-text" *ngIf="0 < dataElm.name.trim().length">{{dataElm.name}}</div>
      <div class="grid-container indetnt">
        <ng-container *ngFor="let childElm of dataElm.children">
          <ng-container *ngTemplateOutlet="dataElement; context: { dataElm: childElm }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #elseBlock>
    <ng-container [ngSwitch]="dataElm.type">
      <ng-container *ngSwitchCase="'note'">
        <div class="section">
          <div>{{dataElm.name}}</div>
          <div class="note" style="position: relative;">
            <div class="is-fill">
              <textarea class="textarea" [(ngModel)]="dataElm.value" spellcheck="false"></textarea>
            </div>
            <div class="textarea-spacer">{{dataElm.value}}</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'numberResource'">
        <div class="tag">{{dataElm.name}}</div>
        <div class="value">
          <div style="display: inline-block; width: min-content;">
            <div class="hidden-spacer" style="margin-right: 2em;">{{dataElm.currentValue}}</div>
            <input class="input" type="number" size="1" [(ngModel)]="dataElm.currentValue" />
          </div>
          <div style="display: inline;"> / {{dataElm.value}}</div>
        </div>
        <div class="section" style="line-height: 1em;">
          <input type="range"
            [style]="'font-size: 0.8em; width: 100%; --min: 0; --max:' + dataElm.value + '; --val:' + dataElm.currentValue +';' | safe: 'style'"
            min="0" max="{{dataElm.value}}" [(ngModel)]="dataElm.currentValue" />
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="tag">{{dataElm.name}}</div>
        <div class="value">
          <div class="hidden-spacer">{{dataElm.value}}</div>
          <input class="input" size="1" [(ngModel)]="dataElm.value" />
        </div>
      </ng-container>
    </ng-container>
    <div class="section border"></div>
  </ng-template>
</ng-template>

<ng-template #imageView>
  <div class="image-view-background is-fill" (click)="chanageImageView(false)" [@fadeInOut]="'in'">
    <div class="close-button is-pointer-events-none"><i class="material-icons">close</i></div>
    <img class="contain-image" [src]="imageUrl | safe: 'resourceUrl'" />
  </div>
</ng-template>