<div class="component is-3d is-pointer-events-none"
  [style.width.px]="width * gridSize" [style.height.px]="depth * gridSize"
  appMovable [movable.option]="movableOption" [movable.disable]="isLocked" (movable.ondragstart)="onMove()" (movable.ondragend)="onMoved()">
  <div class="component-content is-3d is-pointer-events-auto" [ngClass]="{'is-grab': !isLocked}"
    appRotable [rotable.option]="rotableOption" (rotable.ondragstart)="onMove()" (rotable.ondragend)="onMoved()" [rotable.disable]="isLocked">
    <ng-container *ngIf="hasFloor && isVisibleFloor && 0 < floorImage?.url.length">
      <div class="texture" [ngClass]="{'blinking-animation': !isLocked}"
        [style.background-image]="'url(' + floorImage.url + ')' | safe: 'style'"></div>
    </ng-container>
    <ng-container *ngIf="hasWall && 0 < wallImage?.url.length">
      <div *ngIf="isVisibleWallTopBottom" [ngClass]="{'blinking-animation': !isLocked}"
        class="texture of-wall-top" [style.background-image]="'url(' + wallImage.url + ')' | safe: 'style'"
        [style.width.px]="width * gridSize" [style.height.px]="height * gridSize"></div>
      <div *ngIf="isVisibleWallTopBottom" [ngClass]="{'blinking-animation': !isLocked}"
        class="texture of-wall-bottom" [style.background-image]="'url(' + wallImage.url + ')' | safe: 'style'"
        [style.width.px]="width * gridSize" [style.height.px]="height * gridSize"></div>
      <div *ngIf="isVisibleWallLeftRight" [ngClass]="{'blinking-animation': !isLocked}"
        class="texture of-wall-left" [style.background-image]="'url(' + wallImage.url + ')' | safe: 'style'"
        [style.width.px]="depth * gridSize" [style.height.px]="height * gridSize"></div>
      <div *ngIf="isVisibleWallLeftRight" [ngClass]="{'blinking-animation': !isLocked}"
        class="texture of-wall-right" [style.background-image]="'url(' + wallImage.url + ')' | safe: 'style'"
        [style.width.px]="depth * gridSize" [style.height.px]="height * gridSize"></div>
    </ng-container>
    <ng-container *ngIf="!isLocked">
      <div class="pedestal-grab rotate-grab"></div>
      <div class="rotate-grab of-left-top rotate-grab-icon">
        <i class="material-icons">autorenew</i>
      </div>
      <div class="rotate-grab of-left-bottom rotate-grab-icon">
        <i class="material-icons">autorenew</i>
      </div>
      <div class="rotate-grab of-right-bottom rotate-grab-icon">
        <i class="material-icons">autorenew</i>
      </div>
      <div class="rotate-grab of-right-top rotate-grab-icon">
        <i class="material-icons">autorenew</i>
      </div>
    </ng-container>
  </div>
</div>
