<div *ngIf="myPeer">
  <button (click)="changeIcon()">アイコンを変更する</button>
  <div *ngIf="myPeer.image" class="image cover" [style.background-image]="'url(' + myPeer.image.url + ')' | safe: 'style' "
    (click)="changeIcon()">
    <!--<img *ngIf="myPeer.image" [src]="myPeer.image.url | safe: 'resourceUrl'" />-->
  </div>
  <span>あなたのニックネーム：  <input [(ngModel)]="myPeer.name"  placeholder="ニックネーム" /></span>
</div>
<div>
  <span>あなたのID：<span *ngIf="networkService.isOpen" style="font-weight: bold;">{{networkService.peerContext.userId}}</span>
  <span *ngIf="!networkService.isOpen" style="font-weight: bold;">???</span>
  </span>
</div>
<!--<div>host ID:{{networkService.hostPeerID}}</div>-->
<ng-container *ngIf="networkService.peerContext.isRoom">
  <hr/>
  <div>
    <span>ルーム名：<span *ngIf="networkService.isOpen" style="font-weight: bold;">{{networkService.peerContext.roomName}}/{{networkService.peerContext.roomId}}</span></span>
  </div>
  <div>
    <span>パスワード：<span *ngIf="networkService.isOpen" style="font-weight: bold;">{{networkService.peerContext.password}}</span></span>
  </div>
</ng-container>
<hr/>
<div *ngFor="let context of networkService.peerContexts">
  <div>
    <span *ngIf="!context.isOpen">[接続中]</span>
    <span>他の参加者：{{findUserId(context.peerId)}} [{{findPeerName(context.peerId)}}]</span>
  </div>
</div>
<div>
  <ng-container *ngIf="!networkService.peerContext.isRoom">
    <input [(ngModel)]="targetUserId" placeholder="接続したい相手のID" name="targetUserId" />
    <button (click)="connectPeer()" [disabled]="!networkService.isOpen">プライベート接続</button><br>
  </ng-container>
  <button *ngIf="networkService.peerIds.length <= 1" (click)="showLobby()" [disabled]="!networkService.isOpen">ロビー（ルーム一覧）を表示</button>
</div>
<div><span style="font-size: 12px;">{{help}}</span></div>
<ng-container *ngIf="!networkService.peerContext.isRoom">
  <div style="font-size: 12px;" *ngIf="networkService.peerContexts.length < 1">※プライベート接続を使用する場合は、お互いのIDをユドナリウム外で共有してください。</div>
  <div style="font-size: 12px;" *ngIf="networkService.peerContexts.length < 1">※一人で動作確認を行いたい場合はブラウザを2つ開くと良いと思います。</div>
</ng-container>
<ng-container *ngIf="networkService.peerContext.isRoom">
  <div style="font-size: 12px;">※ルーム機能を利用している時はプライベート接続を利用できません。</div>
</ng-container>
