<div class="table" [ngClass]="{ 'direct-message': isDirect }">
  <div class="table-cell imagebox">
    <img *ngIf="0 < imageFile.url.length" class="image" [src]="imageFile.url | safe: 'resourceUrl'" />
  </div>
  <div class="table-cell">
    <div>
      <select style="width: 12em;" [(ngModel)]="sendFrom">
        <option *ngIf="!onlyCharacters" value="{{myPeer?.identifier}}">{{myPeer?.name}}（あなた）</option>
        <option *ngFor="let gameCharacter of gameCharacters" value="{{gameCharacter.identifier}}">{{gameCharacter.name}}
        </option>
      </select> ＞
      <select style="width: 10em;" [(ngModel)]="sendTo">
        <option value="">全員</option>
        <option *ngFor="let peer of otherPeers" value="{{peer.identifier}}">{{peer.name}}
          <ng-container *ngIf="peer === myPeer">（あなた）</ng-container>
        </option>
      </select>
      <select style="width: 12em;" (change)="loadDiceBot($event.target.value)" [(ngModel)]="gameType"
        [ngModelOptions]="{standalone: true}">
        <option value="">ダイスボット指定なし</option>
        <option *ngFor="let diceBotInfo of diceBotInfos" value="{{diceBotInfo.script}}">{{diceBotInfo.game}}</option>
      </select>
      <button (click)="showDicebotHelp()">?</button>
    </div>
    <div>
      <form>
        <textarea class="chat-input" placeholder="Enterで送信  Shift+Enterで改行" [(ngModel)]="text"
          [ngModelOptions]="{standalone: true}" (input)="onInput()" (keydown.enter)="sendChat($event)"
          #textArea></textarea>
        <button type="submit" (click)="sendChat(null)">SEND</button>
      </form>
    </div>
    <div class="writing-info">
      <ng-container *ngIf="0 < writingPeerNames.length">
        <span *ngFor="let peerName of writingPeerNames; index as i" style="font-weight: bold;">{{peerName}}<span
            *ngIf="writingPeerNames.length !== (i + 1)">, </span></span>
        <span> が入力中...</span>
      </ng-container>
    </div>
  </div>
</div>