<div *ngIf="!isConnected;else other_content">
  <button (click)="reload()" [disabled]="isReloading">一覧を更新</button>
  <button (click)="showRoomSetting()">新しいルームを作成する</button>
  <hr/>
  <ng-container *ngIf="rooms.length">
    <table class="room-list">
      <tr>
        <th>ルームID</th>
        <th class="room-name">ルーム名</th>
        <th>パスワード</th>
        <th>参加者</th>
        <th></th>
      </tr>
      <ng-container *ngFor="let room of rooms">
        <tr>
          <td class="is-small-font">{{room.peerContexts[0]?.roomId}}</td>
          <td class="room-name">{{room.roomName}}</td>
          <td *ngIf="room.peerContexts[0].hasPassword">
            <i class="material-icons" style="vertical-align: middle; font-size:0.9rem;">lock</i>
          </td>
          <td *ngIf="!room.peerContexts[0].hasPassword">-</td>
          <td>{{room.peerContexts.length}}人</td>
          <td>
            <button [disabled]="room.peerContexts[0]?.roomId === currentRoom" *ngIf="0 < room.alias.length" type="submit" (click)="connect(room.peerContexts)">接続</button>
          </td>
        </tr>
      </ng-container>
    </table>
  </ng-container>
  <span *ngIf="rooms.length < 1" style="font-size: 12px;">{{help}}</span>
</div>
<ng-template #other_content><span style="font-size: 12px;">別のルームに接続したい場合は、他の参加者との接続を切ってください。<br>（※ページリロードで切断できます。）</span></ng-template>
