<label>
  <div class="drop-zone">
    <div>
      <i class="material-icons large-font">add_photo_alternate</i>
    </div>
    <div>ここに画像をドロップ</div>
    <div class="small-font">またはここをクリックして選択</div>
    <input type="file" style="display: none;" (change)="handleFileSelect($event)" multiple accept="image/*"/>
    <div class="small-font">
      <br>１ファイルにつき2MBまで</div>
  </div>
</label>
<div id="file-list">
  <span *ngFor="let file of fileStorageService.images" class="image">
    <img *ngIf="0 < file.url.length" [src]="file.url | safe: 'resourceUrl'" [alt]="file.name" height="120" (click)="onSelectedFile(file)">
    <img *ngIf="file.url.length <= 0" src="assets/images/loading.gif" alt="{{file.name}}">
  </span>
</div>
